import { Controller } from "stimulus"
import polyline from "@mapbox/polyline"

export default class extends Controller {
	static targets = ["map", "output"]
	
	connect() {
		this.outputTarget.value = ""
		
		const observer = new MutationObserver( (mutationList, observer) => {
			mutationList.forEach(mutation => {
				if (mutation.attributeName === "data-geojson") {
					this.update()
				}
			})
		})
		observer.observe(this.mapTarget, { attributes: true })
	}
	
	update() {
		let geojson = this.mapTarget.dataset.geojson
		let encoded_polyline = polyline.fromGeoJSON(JSON.parse(geojson))
 		this.outputTarget.value = `${this.mapTarget.dataset.rootUrl}/sketches/${encoded_polyline}`
 		// TODO get url from data attribute
	}
}
