import { Controller } from "stimulus"

require("leaflet")
require("leaflet.locatecontrol")
require("leaflet-fullscreen")
require("mapbox-gl")
require("mapbox-gl-leaflet")

import WaypointMarker from "leaflet_extensions/WaypointMarker"
import SegmentPolyline from "leaflet_extensions/SegmentPolyline"
import HighlightControl from "leaflet_extensions/HighlightControl"
import Util from "../jalki/util"

export default class extends Controller {
	static targets = ["map", "segment", "waypoint", "container", "markerPopup", "segmentPopup"]
	
	connect() {
		let mtb_tiles = L.tileLayer(this.mapTarget.dataset.tileserver + "/mtb/{z}/{x}/{y}.png", {
			maxZoom: 16,
			attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		})
		
		let mml_tiles = L.tileLayer("http://tiles.kartat.kapsi.fi/peruskartta/{z}/{x}/{y}.jpg", {
			maxZoom: 18,
			attribution: '&copy; Maanmittauslaitos- Lisenssi CC BY 4.0.'
		})
		
		let orto_tiles = L.tileLayer("http://tiles.kartat.kapsi.fi/ortokuva/{z}/{x}/{y}.jpg", {
			maxZoom: 18,
			attribution: '&copy; Maanmittauslaitos- Lisenssi CC BY 4.0.'
		})
		
		let opentopomap_tiles = L.tileLayer("http://{s}.tile.opentopomap.org/{z}/{x}/{y}.png", {
			maxZoom: 18,
			attribution: '&copy; OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)'
		})
		
		let vector_tiles = new L.MapboxGL({
			// TODO read from data attribute
			style: "/vectortiles/style.json"
		})
		
		let baselayers = {
			"Jälki":       mtb_tiles,
			"Bright":      vector_tiles,
			"MML":         mml_tiles,
			"Ortokuva":        orto_tiles,
			"OpenTopoMap": opentopomap_tiles
		}

		let zoom = null
		let center = null
		let min_zoom = 0
		
		try {
			zoom = this.mapTarget.dataset.zoom || localStorage.getItem("jalki3_mapZoom") || 7
			center = JSON.parse(this.mapTarget.dataset.center) || JSON.parse(localStorage.getItem("jalki3_mapCenter")) || [61.55,23.55]
		}
		catch(error) {
			zoom = 7
			center = [61.55,23.55]
		}
		
		try {
			min_zoom = parseInt(this.mapTarget.dataset.minZoom) || 0
		}
		catch(error) {
			min_zoom = 0
		}
		
		this.map = L.map(this.mapTarget, {
			zoomControl: false,
			gestureHandling: true,
			fullscreenControl: false,
			minZoom: min_zoom,
			layers: [mtb_tiles]
		}).setView(center, zoom)
		
		this.waypointGroup   = L.featureGroup().addTo(this.map)
		this.segmentGroup    = L.featureGroup().addTo(this.map)
		this.toolGroup       = L.featureGroup()
		this.highlight_tiles = new L.MapboxGL({style: "/highlight/style.json"})
		this.hotspot = L.latLng({lat: 0, lng: 0})
		this.mode = "add"
		this.style = this.mapTarget.dataset.mapStyle
		
		L.Icon.Default.prototype.options.imagePath = "/"
		L.Icon.Default.prototype.options.iconUrl = "assets/marker-icon-3d253116ec4ba0e1f22a01cdf1ff7f120fa4d89a6cd0933d68f12951d19809b4.png"
		L.Icon.Default.prototype.options.iconRetinaUrl = "assets/marker-icon-2x-091245b393c16cdcefe54920aa7d3994a0683317ca9a58d35cbc5ec65996398c.png"
		L.Icon.Default.prototype.options.shadowUrl = "assets/marker-shadow-a2d94406ba198f61f68a71ed8f9f9c701122c0c33b775d990edceae4aece567f.png"
		
		if (this.style !== "minimal") {
			L.control.zoom({position: 'topright'}).addTo(this.map)
			L.control.scale({position: 'bottomleft'}).addTo(this.map)
			L.control.fullscreen({position: 'topleft'}).addTo(this.map)
			
			try {
				L.control.locate({position: 'topleft'}).addTo(this.map)
			}
			catch(error) {
				console.log(error)
			}
			
			L.control.layers(baselayers).addTo(this.map)
		}
		
		this.map.on("movestart", event => {
			this._dispatchEvent("mapmovestart", {
				center: this.map.getCenter(),
				zoom: this.map.getZoom(),
				bounds: this.map.getBounds()
			})
		})
		
		this.map.on("move", event => {
			this._dispatchEvent("mapmove", {
				center: this.map.getCenter(),
				zoom: this.map.getZoom(),
				bounds: this.map.getBounds()
			})
		})
		
		this.map.on("moveend", event => {
			this._dispatchEvent("mapmoveend", {
				center: this.map.getCenter(),
				zoom: this.map.getZoom(),
				bounds: this.map.getBounds()
			})
		})
		
		
		if (this.style === "route") {
			this.currentIndex = null
			this.markerIcon = L.divIcon({className: "segment_icon", iconSize: [15, 15]})
			
			this.map.on("mousemove", event => {
				let closest = this._findClosestCoordinate(event.latlng, this.mainTrackCoordinates)
				let closestIndex = this.mainTrackCoordinates.indexOf(closest)
				
				if (closestIndex != this.currentIndex) {
					this.currentIndex = closestIndex
					this._dispatchEvent("maphotspotchanged", {routePointIndex: closestIndex})
				}
				
				let lat = closest[1]
				let lon = closest[0]
				this.showHotspotOnMap({lat: lat, lon: lon})
			})
			this.map.on("mouseout", event => {this.removeHotspotFromMap()})
		}
		
		if (this.style === "route_edit") {
			this.map.on("click", event => {
				if (this.mode === "add") {
					let position = this.waypointGroup.getLayers().length
					let marker = this._addMarker(event.latlng, {position: position})
					this._dispatchEvent("mapclick", {location: event.latlng})
					
					if (this.waypointGroup.getLayers().length > 1) {
						this._route(marker)
					}
				}
				else if (this.mode === "split") {
					this.splitspotSegment.openPopup()
				}
			})
			
			this.map.on("mousemove", event => {
				this.hotspot.lat = event.latlng.lat
				this.hotspot.lng = event.latlng.lng
				
				let distance = this.map.latLngToLayerPoint(this.hotspot).distanceTo(this.map.latLngToLayerPoint(this.splitspot.getLatLng()))
				
				if (distance > 10 && this.mode !== "drag") {
					this.toolGroup.removeLayer(this.splitspot)
					this.splitspotSegmentPosition = null
					this.splitspotSegment = null
					this.mode = "add"
				}
				
				try {
					//let lastMarker = this.waypointGroup.getLayers()[layers.length-1].getLatLng()			
				}
				catch(error) {
					return null
				}
			})
			
			this.map.on("mouseover", event => {
				this.toolGroup.addTo(this.map)
			})
			
			this.map.on("mouseout", event => {
				this.toolGroup.remove()
			})
			
			this.splitspot = this._createMarker({lat: 0, lng: 0}, {})
			
			this.splitspot.on("dragstart", event => {
				this.mode = "drag"
			})
			
			this.splitspot.on("dragend", event => {
				this.mode = "add"
				let position = parseInt(this.splitspotSegmentPosition) + 1
				
				let layers = this.waypointGroup.getLayers()
				
				layers.forEach(marker => {
					if (marker.options.position >= position) {
						marker.options.position++
					}
				})
				
				let marker = this._addMarker(event.target._latlng, {position: position})
				this._updateIcons()
				
				this._route(marker)
				
				this._dispatchEvent("split", {
					location: event.target._latlng,
					position: position
				})
			})
		}
		
		this.reload()
		
		if (this.mapTarget.dataset.geojson) {
			this.draw()
		}
		
		new L.HighlightControl({
			highlightBaseLayer: this.highlight_tiles,
			baseLayer: mtb_tiles
		}).addTo(this.map)
		
		const observer = new MutationObserver( (mutationList, observer) => {
			mutationList.forEach(mutation => {
				if (mutation.type === "attributes" && mutation.attributeName === "data-geojson") {
					this.draw()
				}
			})
		})
		observer.observe(this.mapTarget, { attributes: true })
	}
	
	clear() {
		this.waypointGroup.clearLayers()
		this.segmentGroup.clearLayers()
	}
	
	reload() {
		try {
			this.clear()
			
			this.waypointTargets.forEach(waypoint => {
				let geojson = JSON.parse(waypoint.dataset.geojson)
				let location = {
					lat: geojson.geometry.coordinates[1],
					lng: geojson.geometry.coordinates[0]
				}
				
				this._addMarker(L.latLng(location), geojson.properties)
			})
			
			this._updateIcons()
			
			this.segmentTargets.forEach(segment => {
				let map = segment.querySelector(".map")
				let geojson = JSON.parse(map.dataset.geojson)
				this._addSegment(geojson)
				
				const observer = new IntersectionObserver( (entries, observer) => {
					let entry = entries[0]
					let element = entry.target
					if (window.location.hash === `#segment${element.dataset.id}`) {
						if (entry.isIntersecting) {
							element.style.animationDuration = "2s"
							element.style.animationName = "highlight"
						}
						else {
							element.style.removeProperty("animation-name")
						}
					}
				})
				
				observer.observe(segment)
			})
			
			if (this.segmentTargets.length > 0) {
				this.fitBounds()
			}
		}
		catch(error) {
			console.log(error)
		}
	}
	
	draw() {
		if (this.mapTarget.dataset.geojson == "") {
			this.clear()
		}
		else {
			let geojson = JSON.parse(this.mapTarget.dataset.geojson)
			if (geojson.type === "FeatureCollection") {
				L.geoJSON(geojson, {
					style: () => {
						return {color: "blue", opacity: 0.3}
					},
					onEachFeature: (feature, layer) => {
						layer.on("mouseover", (event) => { event.target.setStyle({color: "#e72a19", opacity: 1}) })
						layer.on("mouseout", (event) => { event.target.setStyle({color: "blue", opacity: 0.3}) })
					}
				}).bindPopup(layer => {
					return document.querySelector(`[data-kind="${layer.feature.properties.kind}"][data-id="${layer.feature.properties.id.toString()}"]`).cloneNode(true)
				}).addTo(this.segmentGroup)
			}
			else {
				let mainTrack = L.geoJSON(geojson).addTo(this.segmentGroup)
				this.mainTrackCoordinates = this._extractCoordinatesFromGeoJson(geojson)
		
				if (this.mapTarget.dataset.fitbounds === undefined || this.mapTarget.dataset.fitbounds === "true") {
					try {
						this.fitBounds()
					}
					catch (err) {
					}
				}
			}
		}
	}
	
	fitBounds() {
		this.map.fitBounds(this.segmentGroup.getBounds())
	}
	
	showElevationChartPointOnMap(event) {
		try {
			let index = event.detail.routePointIndex
			
			if (index !== this.currentIndex) {
				this.currentIndex = index
				let lat = this.mainTrackCoordinates[index][1]
				let lon = this.mainTrackCoordinates[index][0]
				
				this.showHotspotOnMap({lat: lat, lon: lon})
			}
		}
		catch(error) {
		}
	}
	
	showHotspotOnMap(latlng) {
		try {
			if (this.indexMarker) {
				this.indexMarker.setLatLng(latlng)
			}
			else {
				this.indexMarker = L.marker(latlng, {icon: this.markerIcon}).addTo(this.map)
			}
		}
		catch(error) {
			console.log(error)
		}
	}
	
	removeHotspotFromMap() {
		try {
			this.map.removeLayer(this.indexMarker)
			this.indexMarker = null
		}
		catch(error) {
		}
	}
	
	addMarker(event) {
		this._addMarker(event.detail.location, event.detail.options)
	}
	
	removeMarker(event) {
		// This is the marker we want to remove.
		//let position = event.target.dataset.position
		let position = this._leafletIdToPosition(event.target.dataset.leafletId)
		
		let marker = this._findWaypoint(position)
		
		// Remove it first before updating positions for the rest of the markers. This
		// is to avoid the mess that could happen if a waypoint was removed inside the loop.
		this.waypointGroup.removeLayer(marker)
		
		this.waypointGroup.getLayers().forEach(marker => {
			if (marker.options.position >= position) {
				marker.options.position--
			}
		})
		
		this._updateIcons()
		
		// After the waypoint is removed, both preceding and following segments become invalid,
		// so we need to remove them too.
		let precedingPolyline = this._findSegment(position - 1)
		this.segmentGroup.removeLayer(precedingPolyline)
		let followingPolyline = this._findSegment(position)
		this.segmentGroup.removeLayer(followingPolyline)
		
		// To replace missing segment, create a new route from the waypoint preceding the
		// removed one.
		let precedingMarker = this._findWaypoint(position - 1)
		this._route(precedingMarker)
		
		this._dispatchEvent("waypointremove", {
			position: position,
			waypointId: marker.options.waypointId
		})
	}
	
	addSegment(event) {
		this._addSegment(event.detail.geojson)
	}
	
	updateLocationToLocalstorage() {
		localStorage.setItem("jalki3_mapCenter", `[${this.map.getCenter().lat},${this.map.getCenter().lng}]`)
		localStorage.setItem("jalki3_mapZoom", this.map.getZoom())
	}
	
	_createMarker(latLng, options = {}) {
		let default_options = {
			waypointId: null,
			className: "segment_icon",
			url: null,
			keepInCenter: false,
			position: null,
			draggable: true
		}
		
		options = Object.assign({}, default_options, options)
		
		let icon = null
		
		if (options.className) {
			icon = L.divIcon({
				className: options.className,
				iconSize: L.point(16, 16)
			})
		}
		else {
			icon = new L.Icon.Default
		}
		
		let marker = new L.WaypointMarker(latLng, {
			draggable: options.draggable,
			icon: icon,
			waypointId: options.waypointId,
			url: options.url,
			keepInCenter: options.keepInCenter,
			position: options.position
		})
		
		return marker
	}
	
	_addMarker(latLng, options) {
		let marker = this._createMarker(latLng, options)
		
		if (this.style === "place_edit") {
			marker.options.draggable = false
			
			this.map.on("move", event => {
				if (event.flyTo !== true) {
					 marker.setLatLng(this.map.getCenter())
				}
			})
		}
		else if (this.style === "normal") {
			marker.options.draggable = false
		}
		
		marker.addTo(this.waypointGroup)
		this._updateIcons()
		
		if (this.hasMarkerPopupTarget) {
			let popupContent = this.markerPopupTarget.cloneNode(true)
			popupContent.dataset.position = options.position
			popupContent.dataset.leafletId = marker._leaflet_id
			marker.bindPopup(popupContent, {autoClose: true, closeButton: true, minWidth: 180})
		}
		
		marker.on("dragend", event => {
			this._dispatchEvent("waypointupdate", {
				location: marker.getLatLng(),
				waypointId: marker.options.waypointId
			})
			this._route(marker)
		})
		
		this._dispatchEvent("waypointcreate", {
			location: marker.getLatLng(),
			position: options?.position
		})
		
		return marker
	}
	
	_createPolyline(geojson) {
		let coordinates = geojson.geometry.coordinates.map(coord => L.latLng({
			lat: coord[1], lng: coord[0]
		}))
		
		let default_options = {
			segmentId: null,
			position: null,
			color: "blue"
		}
		
		let options = Object.assign({}, default_options, geojson.properties)
		
		let polyline = new L.SegmentPolyline(coordinates, options)
		
		return polyline
	}
	
	
	_addSegment(geojson) {
		let polyline = this._createPolyline(geojson).addTo(this.map)
		
		polyline.on("mouseover", event => {
			this.mode = "split"
			this.splitspotSegmentPosition = event.target.options.position
			this.splitspotSegment = event.target
			this.splitspot.addTo(this.toolGroup)
		})
		
		polyline.on("mousemove", event => {
			this.splitspot.setLatLng(event.latlng)
		})
		
		if (this.hasSegmentPopupTarget) {
			let popupContent = this.segmentPopupTarget.cloneNode(true)
			popupContent.href = `#segment${polyline.options.segmentId}`
			polyline.bindPopup(popupContent, {autoClose: true, closeButton: true, minWidth: 180})
		}
		
		polyline.addTo(this.segmentGroup)
		
		return polyline
	}
	
	_route(waypoint) {
		let preceding = this._findWaypoint(waypoint.options.position - 1)
		
		if (preceding) {
			let first = Util.latlng2wkt(preceding.getLatLng())
			let second = Util.latlng2wkt(waypoint.getLatLng())
			
			let oldPolyline = this.segmentGroup.getLayers().filter( layer => {
				return layer.options.position == waypoint.options.position - 1
			})[0]

			let params = [first, second].join(",")
			fetch(`/routings/route.json?waypoints=${params}`)
			.then(response => response.json())
			.then(data => {
				this.segmentGroup.removeLayer(oldPolyline)
				data.properties = {position: waypoint.options.position - 1}
				this._addSegment(data)
				
				this._updateMapGeoJson()
			})
		}
		
		let following = this._findWaypoint(waypoint.options.position + 1)
		
		if (following) {
			let first = Util.latlng2wkt(waypoint.getLatLng())
			let second = Util.latlng2wkt(following.getLatLng())
			let oldPolyline = this.segmentGroup.getLayers().filter( layer => {
				return layer.options.position == waypoint.options.position
			})[0]
			
			let params = [first, second].join(",")
			fetch(`/routings/route.json?waypoints=${params}`) // TODO read from data attribute
			.then(response => response.json())
			.then(data => {
				this.segmentGroup.removeLayer(oldPolyline)
				data.properties = {position: waypoint.options.position}
				this._addSegment(data)
				
				this._updateMapGeoJson()
			})
			
		}
	}
	
	_updateMapGeoJson() {
		let coordinates = []
		
		let layers = this.segmentGroup.getLayers()
		layers.sort((a,b) => { return a.options.position - b.options.position })
		layers.forEach(layer => {
			coordinates = coordinates.concat(layer.toGeoJSON().geometry.coordinates)
		})
		
		let geojson = {
			type: "Feature",
			geometry: {
				type: "LineString",
				coordinates: coordinates
			}
		}
		
		this.mapTarget.dataset.geojson = JSON.stringify(geojson)
	}
	
	_dispatchEvent(name, details) {
		try {
			let customEvent = new CustomEvent(name,
				{
					bubbles: true,
					detail: details
				}
			)
			this.map.getContainer().dispatchEvent(customEvent)
		}
		catch(error) {}
	}
	
	_findWaypoint(position) {
		let waypoint = null
		
		try {
			waypoint = this.waypointGroup.getLayers().filter(layer => {
				return layer.options.position == position
			})[0]
		}
		catch(error) {}
		
		return waypoint
	}
	
	_findSegment(position) {
		let polyline = null
		
		try {
			polyline = this.segmentGroup.getLayers().filter(layer => {
				return layer.options.position == position
			})[0]
		}
		catch(error) {}
		
		return polyline
	}
	
	_leafletIdToPosition(leafletId) {
		let position = null
		
		try {
			let marker = this.waypointGroup.getLayers().filter(layer => {
				return layer._leaflet_id == leafletId
			})[0]
			position = marker.options.position
		}
		catch(error) {}
		
		return position
	}
	
	_updateIcons() {
		if (this.style == "normal" || this.style === "place_edit") {
			return
		}
		
		let layers = this.waypointGroup.getLayers()
		let html = ""
		
		layers.forEach(marker => {
			// This is stupid check because JS condiders 0 as false, but here 0 is
			// valid content.
			if (marker.options.position || marker.options.position === 0) {
				html = marker.options.position+1
			}
			
			let icon = marker.getIcon()
			icon.options.className = "segment_icon"
			icon.options.html = html
			marker.setIcon(icon)
		})
		
		let firstMarker = layers[0]
		
		if (firstMarker) {
			let firstIcon = firstMarker.getIcon()
			firstIcon.options.className = "segment_start_icon"
			firstMarker.setIcon(firstIcon)
		}
		
		let lastMarker = layers[layers.length-1]
		
		if (lastMarker && layers.length >= 2) {
			let lastIcon = lastMarker.getIcon()
			lastIcon.options.className = "segment_end_icon"
			lastMarker.setIcon(lastIcon)
		}
	}
	
	_findClosestCoordinate(latlng, collection) {
		let closest = null
		let smallestDistance = Infinity
		
		collection.forEach( coordinate => {
			let lat = coordinate[1]
			let lon = coordinate[0]
			
			let distance = L.latLng({lat: lat, lon: lon}).distanceTo(latlng)
			
			if (distance < smallestDistance) {
				smallestDistance = distance
				closest = coordinate
			}
		})
		
		return closest
	}
	
	_extractCoordinatesFromGeoJson(geojson) {
		let coordinates = null
		let feature = null
		
		if (geojson.type == "FeatureCollection") {
			if (geojson.features.length > 0) {
				feature = geojson.features[0]
			}
		}
		else if (geojson.type == "Feature") {
			feature = geojson
		}
		
		if (feature) {
			coordinates = feature.geometry.coordinates
		}
		
		return coordinates
	}
}
