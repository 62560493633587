var verticalLinePlugin = {
	afterDraw: (chart, easing) => {
		var ctx, meta, scale, xOffset, xPosition
		xPosition = chart.options.xPosition
		meta = chart.getDatasetMeta(0)
		xOffset = meta.data[xPosition]._model.x
		ctx = chart.chart.ctx
		scale = chart.scales['y-axis-0']
		ctx.beginPath()
		ctx.strokeStyle = '#0000ff'
		ctx.moveTo(xOffset, scale.top)
		ctx.lineTo(xOffset, scale.bottom)
		ctx.stroke()
	}
}

Chart.plugins.register(verticalLinePlugin)
