var L = L || require('leaflet')

L.WaypointMarker = L.Marker.extend({
	options: {
		waypointId: null,
		url: null,
		keepInCenter: false,
		position: null
	}
})

if (typeof module === 'object' && typeof module.exports === 'object') {
	module.exports = L
}
else if (typeof define === 'function' && define.amd) {
	define(L)
}
