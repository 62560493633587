import { Controller } from "stimulus"

import Chart from "chart.js"
import VerticalLine from "chartjs_extensions/VerticalLine"

export default class extends Controller {
	static targets = ["canvas"]
	
	initialize() {
		this.currentIndex = null
		
		this.initChart()
	}
	
	initChart() {
		let elevation_data = JSON.parse(this.canvasTarget.dataset.values)
		let data = {
			datasets: [ {
				label: "Elevation",
				backgroundColor: 'rgba(0,0,0,0.4)',
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 1,
				pointColor: 'rgba(0,0,0,1)',
				pointRadius: 0,
				pointStrokeColor: '#fff',
				pointHighlightFill: '#fff',
				pointHighlightStroke: 'rgba(220,220,220,1)',
				data: elevation_data
			} ]
		}
		
		let options = {
			animation: {duration: 0},
			bezierCurve: false,
			customTooltip: false,
			datasetFill: true,
			datasetStroke: true,
			hover: {animationDuration: 0, intersect: false},
			maintainAspectRatio: false,
			onHover: this.onChartMouseEvent,
			pointDot: false,
			pointDotRadius: 1,
			pointHitDetectionRadius: 1,
			responsive: true,
			responsiveAnimationDuration: 0,
			scales: {
				xAxes: [{
					gridLines: {display: false},
					type: "linear",
					position: "bottom",
					ticks: {
						min: 0,
						max: elevation_data[elevation_data.length-1].x,
						callback: (value, index, values) => {
							let distance = parseInt(value)
							let formatted = Math.round(distance/1000)
							
							return formatted + " km"
						}
					}
				}],
				yAxes: [{
					ticks: {
						min: 0,
						callback: (value, index, values) => {
							return value + " m"
						}
					}
				}]
			},
			scaleShowVerticalLines: false,
			//tooltipTemplate: "",
			tooltips: {
				callbacks: {
					title: (tooltipitem, data) => {
						let distance = parseInt(tooltipitem[0].label)
						let formatted = Math.round(distance/10)/100
							
						return formatted + " km"
					},
					label: (tooltipitem, data) => {
						let elevation = parseInt(tooltipitem.value)
						let formatted = Math.round(elevation * 10) / 10
							
						return formatted + " m"
					}
				}
			},
			xPosition: 0 // This is a custom property for vertical line
		}
		
		let ctx = this.canvasTarget.getContext("2d")
		
		this.chart = new Chart(ctx, {
			type: "line",
			data: data,
			options: options
		})
	}
	
	onChartMouseEvent(event, elements) {
		if (event.type === "mousemove") {
			let element = elements[0]
			let index = element._index
			
			if (index !== this.currentIndex) {
				this.currentIndex = index
				this.chart.options.xPosition = index
				this.chart.update()
			}
			
			let customEvent = new CustomEvent("elevationchartmousemove", { bubbles: true, detail: {routePointIndex: index} })
			event.target.dispatchEvent(customEvent)
		}
		else if (event.type === "mouseout") {
			let customEvent = new CustomEvent("elevationchartmouseout", { bubbles: true })
			event.target.dispatchEvent(customEvent)
		}
	}
	
	updateRoutePointIndex(event) {
		let index = event.detail.routePointIndex
		this.chart.options.xPosition = index
		this.chart.update()
	}
}
