// These helper methods are adapter from Leaflet.draw.

export default class {
	static formattedNumber(n, precision) {
		let formatted  = parseFloat(n).toFixed(precision)
		// let format     = L.drawLocal.format && L.drawLocal.format.numeric
		// let delimiters = format && format.delimiters
		// let thousands  = delimiters && delimiters.thousands
		// let decimal    = delimiters && delimiters.decimal
		
		let thousands = null
		let decimal = null

		if (thousands || decimal) {
			let splitValue = formatted.split('.')
			formatted = thousands ? splitValue[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousands) : splitValue[0]
			decimal = decimal || '.'
			
			if (splitValue.length > 1) {
				formatted = formatted + decimal + splitValue[1]
			}
		}

		return formatted
	}
	
	static readableDistance(distance, isMetric, isFeet, isNauticalMile) {
		let distanceStr
		let units
		let precision = {km: 2, ha: 2, m: 0, mi: 2, ac: 2, yd: 0, ft: 0, nm: 2}

		if (isMetric) {
			units = typeof isMetric == 'string' ? isMetric : 'metric'
		}
		else if (isFeet) {
			units = 'feet'
		}
		else if (isNauticalMile) {
			units = 'nauticalMile'
		}
		else {
			units = 'yards'
		}

		switch (units) {
			case 'metric':
				// show metres when distance is < 1km, then show km
				if (distance > 1000) {
					distanceStr = this.formattedNumber(distance / 1000, precision['km']) + ' km'
				} else {
					distanceStr = this.formattedNumber(distance, precision['m']) + ' m'
				}
				break
			case 'feet':
				distance *= 1.09361 * 3
				distanceStr = this.formattedNumber(distance, precision['ft']) + ' ft'
				break
			case 'nauticalMile':
				distance *= 0.53996
				distanceStr = this.formattedNumber(distance / 1000, precision['nm']) + ' nm'
				break
			case 'yards':
			default:
				distance *= 1.09361

				if (distance > 1760) {
					distanceStr = this.formattedNumber(distance / 1760, precision['mi']) + ' miles'
				} else {
					distanceStr = this.formattedNumber(distance, precision['yd']) + ' yd'
				}
				break
		}
		return distanceStr
	}
	
	static geojson2wkt(geojson) {
		if (geojson.type === "FeatureCollection") {
			geojson = geojson.features[0]
		}
		
		if (geojson.type === "Feature") {
			geojson = geojson.geometry
		}
		
		switch (geojson.type) {
			case "Point":
				return `POINT(${geojson.coordinates.join(" ")})`
				break;
				
			case "LineString":
				return `LINESTRING(${geojson.coordinates.map(c => c.join(",")).join(" ")})`
				break;
			default:
				throw new Error("Unsupported Feature type");
		}
	}
	
	static latlng2wkt(latlng) {
		return `POINT(${latlng.lng} ${latlng.lat})`
	}
}
