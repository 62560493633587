var L = L || require('leaflet')

L.SegmentPolyline = L.Polyline.extend({
	options: {
		segmentId: null,
		position: null,
		from: null,
		to: null
	}
})

if (typeof module === 'object' && typeof module.exports === 'object') {
	module.exports = L
}
else if (typeof define === 'function' && define.amd) {
	define(L)
}
