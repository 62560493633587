var L = L || require("leaflet")

L.HighlightControl = L.Control.extend({
	options: {
		collapsed: true,
		position: "topright",
		autoZIndex: true,
		baseLayer: null,
		highlightBaseLayer: null
	},

	initialize(options) {
		this._layerControlInputs = []
		this._layers = []
		this._lastZIndex = 0
		this._handlingClick = false
		
		this.flags = [
			false, false, false, false,
			false, false, false
		]
		
		this.queryTypes = [
			"paths_0", "paths_1", "paths_2", "paths_3",
			"paved", "unpaved", "cobblestone"
		]
		
		for (var i in this.options) {
			if (typeof options[i] === "object") {
				this.options[i] = options[i]
			}
		}
	},

	onAdd(map) {
		this._initLayout()
		this._update()

		this._map = map

		return this._container
	},

	onRemove() {
		
	},

	expand() {
		L.DomUtil.addClass(this._container, 'leaflet-control-layers-expanded')
		this._section.style.height = null
		var acceptableHeight = this._map.getSize().y - (this._container.offsetTop + 50)
		
		if (acceptableHeight < this._section.clientHeight) {
			L.DomUtil.addClass(this._section, 'leaflet-control-layers-scrollbar')
			this._section.style.height = acceptableHeight + 'px'
		}
		else {
			L.DomUtil.removeClass(this._section, 'leaflet-control-layers-scrollbar')
		}
		
		return this
	},

	collapse() {
		L.DomUtil.removeClass(this._container, 'leaflet-control-layers-expanded')
		return this
	},

	_initLayout() {
		var className = 'leaflet-control-layers',
		    container = this._container = L.DomUtil.create('div', className),
		    collapsed = this.options.collapsed

		// makes this work on IE touch devices by stopping it from firing a mouseout event when the touch is released
		container.setAttribute('aria-haspopup', true)

		L.DomEvent.disableClickPropagation(container)
		L.DomEvent.disableScrollPropagation(container)

		var section = this._section = L.DomUtil.create('section', className + '-list')

		if (collapsed) {
			this._map.on('click', this.collapse, this)

			if (!L.Browser.android) {
				L.DomEvent.on(container, {
					mouseenter: this.expand,
					mouseleave: this.collapse
				}, this)
			}
		}

		var link = this._layersLink = L.DomUtil.create('a', className + '-toggle', container)
		link.href = '#'
		link.title = 'Layers'

		if (L.Browser.touch) {
			L.DomEvent.on(link, 'click', L.DomEvent.stop)
			L.DomEvent.on(link, 'click', this.expand, this)
		}
		else {
			L.DomEvent.on(link, 'focus', this.expand, this)
		}

		if (!collapsed) {
			this.expand()
		}

		let separator = L.DomUtil.create('div', className + '-separator', section)
		
		let paths_0 = this._createItem("Helpot", 0)
		let paths_1 = this._createItem("Melko helpot", 1)
		let paths_2 = this._createItem("Kohtalaiset vaikeat", 2)
		let paths_3 = this._createItem("Vaikeat", 3)
		
		let roads_paved = this._createItem("Päällystetyt", 4)
		let roads_unpaved = this._createItem("Päällystämättömät", 5)
		let roads_cobblestone = this._createItem("Mukulakivet", 6)
		
		let heading1 = document.createElement('div')
		heading1.classList.add("highlight-section-heading")
		heading1.innerHTML = "Polkukorostukset"
		
		let heading2 = document.createElement('div')
		heading2.classList.add("highlight-section-heading")
		heading2.innerHTML = "Tiekorostukset"
		
		section.appendChild(heading1)
		
		section.appendChild(paths_0)
		section.appendChild(paths_1)
		section.appendChild(paths_2)
		section.appendChild(paths_3)
		
		section.appendChild(separator)
		section.appendChild(heading2)
		
		section.appendChild(roads_paved)
		section.appendChild(roads_unpaved)
		section.appendChild(roads_cobblestone)
		
		container.appendChild(section)
	},

	_update() {
		if (!this._container) { return this }

		this._layerControlInputs = []
		var overlaysPresent, i, obj, baseLayersCount = 0

		for (i = 0; i < this._layers.length; i++) {
			obj = this._layers[i]
			this._addItem(obj)
			overlaysPresent = overlaysPresent || obj.overlay
			baseLayersCount += !obj.overlay ? 1 : 0
		}

		return this
	},
	
	_createItem(name, value, selector) {
		let inputTag = document.createElement("input")
		inputTag.type = "checkbox"
		inputTag.defaultChecked = false
		
		// Helps from preventing layer control flicker when checkboxes are disabled
		// https://github.com/Leaflet/Leaflet/issues/2771
		let holderTag = document.createElement('div')

		let labelTag = document.createElement("label")
		
		let nameTag = document.createElement("span")
		nameTag.innerHTML = ` ${name}`
	
		holderTag.appendChild(inputTag)
		holderTag.appendChild(nameTag)
		labelTag.appendChild(holderTag)
		
		L.DomEvent.on(inputTag, "click", (event) => {this._click(event, value)}, this)
		
		return labelTag
	},
	
	_click(event, index) {
		this.flags[index] = !this.flags[index]
		
		if (this.flags.includes(true)) {
			this.options.baseLayer.remove()
			this.options.highlightBaseLayer.addTo(this._map)
			
			this.options.highlightBaseLayer._glMap.on("styledata", () => {
				this.flags.forEach( (flag, index) => {
					let opacity = flag ? 1 : 0
					let item = this.queryTypes[index]
					this.options.highlightBaseLayer._glMap.setPaintProperty(item, "line-opacity", opacity)
				})
			})
			
			try {
				this.queryTypes.forEach( (item, index) => {
					let opacity = this.flags[index] ? 1 : 0
					this.options.highlightBaseLayer._glMap.setPaintProperty(item, "line-opacity", opacity)
				})
			}
			catch(error) {
				
			}
		}
		else {
			this.options.baseLayer.addTo(this._map)
			this.options.highlightBaseLayer.remove()
		}
    },
});

if (typeof module === "object" && typeof module.exports === "object") {
	module.exports = L
}
else if (typeof define === "function" && define.amd) {
	define(L)
}
