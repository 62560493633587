import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		setTimeout(() => this.hide_messages(), 5000)
	}
	
	hide_messages() {
		let parent = this.element
		let children = parent.getElementsByTagName("P")
		
		for (var i = 0; i < children.length; i++) {
			let child = children[i]
			if (!child.classList.contains("sticky")) {
				//child.style.display = "none"
			}
		}
	}
}
