import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["tab", "link", "content"]
	
	initialize() {
		this.linkTargets.forEach(link => {
			this.intercept(link)
		})
	}
	
	intercept(link) {
		link.addEventListener("click", event => {
			event.preventDefault()
			
			this.tabTarget.querySelectorAll("span").forEach(element => {
				if (element == event.target) {
					if (!element.classList.contains("active")) {
						element.classList.add("active")
					}
				}
				else {
					element.classList.remove("active")
				}
			})
			
			let targetId = event.target.dataset.target
			
			this.contentTarget.querySelectorAll(".tab-content").forEach(element => {
				if (element.id == targetId) {
					if (!element.classList.contains("active")) {
						element.classList.add("active")
					}
				}
				else {
					element.classList.remove("active")
				}
			})
		})
	}
}
