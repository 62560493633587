import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["map"]
	
	connect() {
		let tiles = L.tileLayer(this.mapTarget.dataset.tileserver + "/mtb/{z}/{x}/{y}.png", {
			maxZoom: 16,
			attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		})
		let intitialZoom = 7
		let center = [0.0, 0.0]
		
		let map = L.map(this.mapTarget, {
			zoomControl: false,
			gestureHandling: false,
			fullscreenControl: false,
			layers: [tiles]
		}).setView(center, intitialZoom)
		
		L.Icon.Default.imagePath = "/assets/" // TODO read from data attribute
		
		let geojson = JSON.parse(this.mapTarget.dataset.geojson)
		let geojsonLayer = L.geoJSON(geojson).addTo(map)
		map.fitBounds(geojsonLayer.getBounds())
	}
}
