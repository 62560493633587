import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["content", "inner"]
	
	connect() {
		this.contentTarget.addEventListener("click", (event) => {
			this.close()
		})
		
		this.innerTarget.addEventListener("click", (event) => {
			event.stopPropagation()
		})
	}
	
	open() {
		this.contentTarget.style.display = "block"
		this.element.addEventListener("keyup", this.listen)
	}
	
	close() {
		this.contentTarget.style.display = "none"
	}
	
	listen(event) {
		if (event.key === "Escape") {
			document.querySelector(".modal-container").style.display = "none"
		}
	}
	
	
}
