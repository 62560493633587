import { Controller } from "stimulus"
import Util from "../jalki/util"

export default class extends Controller {
	static targets = ["update"]
	
	update(event) {
		this.updateTarget.value = Util.latlng2wkt(event.detail.center)
	}
}
