import { Controller } from "stimulus"
import Util from "../jalki/util"

export default class extends Controller {
	static targets = ["container", "waypointForm"]
	
	createWaypoint(event) {
		let value = Util.latlng2wkt(event.detail.location)
		let position = event.detail.position
		let form = this.waypointFormTarget.querySelector("form")
		form.querySelector("#waypoint_location").value = value
			
		if (position) {
			form.querySelector("#waypoint_position").value = parseInt(position)
		}
		
		Rails.fire(form, "submit")
		form.reset()
	}
	
	updateWaypoint(event) {
		let element = this.containerTarget.querySelector(`[data-waypoint-id="${event.detail.waypointId}"]`)
		let form = element.querySelector("form")
		form.querySelector("#waypoint_location").value = Util.latlng2wkt(event.detail.location)

		Rails.fire(form, "submit")
	}
	
	removeWaypoint(event) {
		let element = this.containerTarget.querySelector(`[data-waypoint-id="${event.detail.waypointId}"]`)
		let button = element.querySelector("a.button.remove")
		button.click()
	}
	
	_dispatchEvent(name, details) {
		try {
			let customEvent = new CustomEvent(name, {
					bubbles: true,
					detail: details
			})
			this.containerTarget.dispatchEvent(customEvent)
		}
		catch(error) {
			console.log(error)
		}
	}
}
